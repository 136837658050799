import React from "react";
import AppRouter from "./components/router";
import { useAuthStore } from "./store";
import { PortalLoader } from "./components/@ui/Loader";
import GlobalContext from "./global.context";

function App() {
  const { signInByToken } = useAuthStore();

  React.useEffect(() => {
    const init = async () => {
      await signInByToken(localStorage.getItem("auth") as string);
    };

    init();
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <PortalLoader />
      <GlobalContext>
        <AppRouter />
      </GlobalContext>
    </React.Fragment>
  );
}

export default React.memo(App);
