import { ConfigProvider, App as AppAntD, theme } from "antd";
import twColors from "tailwindcss/colors";
import * as React from "react";
import { colors } from "./utils";
import { BrowserRouter } from "react-router-dom";
import es_ES from "antd/locale/es_ES";
import { useDarkMode } from "./hooks";
import { ThemeConfig } from "antd/lib";

interface IGlobalContextProps {
  children?: React.ReactNode;
}

const themeDark: ThemeConfig = {
  algorithm: theme.darkAlgorithm,
  token: {
    colorBgBase: colors.dark[900],
    colorBgContainer: colors.dark[800],
  },
  components: {
    Modal: {
      contentBg: colors.dark[900],
      headerBg: colors.dark[900],
    },
    Select: {},
    Drawer: {
      colorBgBase: colors.dark[800],
      colorBgLayout: colors.dark[800],
      colorBgContainer: colors.dark[800],
      colorBgElevated: colors.dark[800],
    },
    Breadcrumb: {
      colorPrimary: twColors.sky[300],
      colorText: twColors.sky[300],
      colorPrimaryActive: twColors.sky[300],
      colorLinkActive: twColors.sky[300],
      colorInfoActive: twColors.sky[300],
      colorPrimaryTextActive: twColors.sky[300],
      colorBgTextActive: twColors.sky[300],
      colorInfoTextActive: twColors.sky[300],
    },
  },
};

const GlobalContext: React.FunctionComponent<IGlobalContextProps> = (props) => {
  const {} = props;
  return (
    <ProviderTheme>
      <AppAntD notification={{ placement: "bottomRight" }}>
        <BrowserRouter>{props.children}</BrowserRouter>
      </AppAntD>
    </ProviderTheme>
  );
};

export default GlobalContext;

const ProviderTheme = (props: { children: React.ReactNode }) => {
  const { theme: _theme } = useDarkMode();

  return (
    <ConfigProvider locale={es_ES} theme={_theme === "dark" ? themeDark : undefined}>
      {props.children}
    </ConfigProvider>
  );
};
