import { axiosClient } from "@/utils";
import { ManageErrorApiResponse, ManageErrorResponse } from ".";

export const authService = {
  signUp: async (data: SignUpDto): Promise<SignUpResponse> => {
    const res = await axiosClient.post<SignUpResponse>("/create-account", data);

    return res.data;
  },

  signIn: async (data: SignInDto): Promise<SignInResponse> => {
    try {
      const req = await axiosClient.post("/login", data);
      const token = req.headers["authorization"];
      if (typeof token !== "string") {
        throw new Error(JSON.stringify({ error: "There was an error" }));
      }
      return { token, success: Boolean(req.data.success && token) };
    } catch (error) {
      console.log(error);
      const errorManage = (error as any).response.data as ManageErrorApiResponse;
      return { success: false, errorKey: errorManage["field-error"][1] };
    }
  },
};

export interface SignInDto {
  login: string;
  password: string;
}

export interface SignInResponse extends ManageErrorResponse {
  token?: string;
  success: boolean;
}

export interface SignUpDto {}

export interface SignUpResponse {}

/* const [formData, setFormData] = useState({
    login: "",
    password: "",
    'password-confirm': "",
    first_name: "",
    last_name: "",
    terms_agreement: false,
    cellphone: "",
    survey: {
      submitted_at: new Date().toISOString(),
      location_tag: "",
      location_other: "",
      purchase_date: "",
      property_price: 0,
      ref_url: document.referrer
    }
  })
  const [formErrors, setFormErrors] = useState({
    login: "",
    password: "",
    'password-confirm': "",
    first_name: "",
    last_name: "",
    terms_agreement:"",
    cellphone: ""
  }) */
/* signup(formData, callback) {
    fetch(`${process.env.NEXT_PUBLIC_API_URL}/create-account`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    })
      .then(async (response) => ({ 
        data: await response.json(),
        token: response.headers.get("Authorization"),
        status: response.status,
       }))
      .then((data) => callback(data));
  },
  signin(formData, callback) {
    fetch(`${process.env.NEXT_PUBLIC_API_URL}/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    })
      .then(async (response) => ({ 
        data: await response.json(),
        token: response.headers.get("Authorization"),
        status: response.status,
       }))
      .then((data) => callback(data));
  },
  signout(callback) {
    fetch(`${process.env.NEXT_PUBLIC_API_URL}/logout`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => response.json())
      .then(data => callback(data));
  },
 */
