export const apiSchema = {
  profiles: {
    fields: {
      first_name: "string",
      last_name: "string",
      avatar: "string",
      cellphone: "string",
    },
  },
  properties: {
    type: "properties",
    fields: {
      title: "string",
      description: "string",
      built_at: "string",
      rooms: "number",
      bathrooms: "number",
      area: "number",
      area_units: "string",
      floors: "number",
      parking_lots: "number",
      latitude: "number",
      longitude: "number",
      street_address: "string",
      street_number: "string",
      postal_code: "string",
      images: "array",
      video_url: "string",
    },
    relationships: {
      features: {
        type: "features",
      },
      status: {
        type: "tag",
      },
      type: {
        type: "tag",
      },
      location: {
        type: "tag",
      },
    },
  },
  accounts: {
    type: "accounts",
    fields: {
      email: "string",
      role: "string",
      status: "verified",
      id: "string",
    },
    relationships: {
      profile: {
        type: "profile",
      },
    },
  },

  tags: {
    fields: {
      global_key: "string",
      label: "string",
      category: "string",
    },
  },
  features: {
    relationships: {
      tag: {
        type: "tag",
      },
    },
  },
};
