import axios from "axios";
import { ENV } from ".";
const instance = axios.create({ baseURL: `${ENV.API_URL}` });

instance.interceptors.request.use((req) => {
  if (localStorage.getItem("auth")) {
    req.headers.setAuthorization(localStorage.getItem("auth"));
  }

  return req;
});

export const axiosClient = instance;
