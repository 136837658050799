import classNames from "classnames";
import ReactDOM from "react-dom";
import Spinner from "@/components/@ui/Spinner";

interface ILoaderProps {
  loading?: boolean;
  className?: string;
  isPortal?: boolean;
}

const Loader: React.FunctionComponent<ILoaderProps> = (props) => {
  const { loading, className, isPortal } = props;
  if (!loading) return null;
  return (
    <div
      className={classNames(
        className || "fixed h-screen w-full bg-white/90 dark:bg-dark-900 z-50",
        !isPortal && ""
      )}
    >
      <div className="flex items-center justify-center w-full h-full">
        <Spinner size={40} className={classNames("text-blue-600")} />
      </div>
    </div>
  );
};

export default Loader;

export const PortalLoader: React.FunctionComponent = () =>
  ReactDOM.createPortal(
    <Loader loading={true} isPortal />,
    document.getElementById("portal-loader") as Element
  );

// eslint-disable-next-line react-refresh/only-export-components
export const showLoader = () => {
  document.getElementById("portal-loader")?.classList.remove("hidden");
};

// eslint-disable-next-line react-refresh/only-export-components
export const hideLoader = () => {
  document.getElementById("portal-loader")?.classList.toggle("hidden");
};
