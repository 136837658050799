import { PageProps } from "@/utils";
import { lazy, memo } from "react";

export const teamsPages = {
  teams: {
    component: memo(lazy(() => import("./index"))),
    path: "/broker/crm/teams",
    authoritys: "*",
  } as PageProps,
};
