import { create } from "zustand";

interface IAuthValues {
  theme: ThemeType;
  setTheme: (theme: ThemeType) => void;
}
export type ThemeType = "light" | "dark";

export const _useDarkMode = create<IAuthValues>((set) => ({
  theme: (localStorage.getItem("color-theme") as ThemeType) || "light",
  setTheme: (theme) => {
    set({ theme });
  },
}));
