import { Lead } from "@/interfaces/Lead";
import { ResponseGetLeads } from "@/services";
import { QueryObserverRefetchErrorResult, QueryObserverSuccessResult } from "@tanstack/react-query";
import { BsCalendarDate, BsEnvelopePaper } from "react-icons/bs";
import { CgSandClock } from "react-icons/cg";
import { FaClock, FaRegHandshake, FaTrophy } from "react-icons/fa";
import { LiaTimesSolid } from "react-icons/lia";
import { LuGoal } from "react-icons/lu";
import { MdFollowTheSigns } from "react-icons/md";
import { TbUserPentagon } from "react-icons/tb";

export enum ValidStatusProspectos {
  EN_PROGRESO = "in_progress",
  STANDBY = "standby",
  WON = "won",
  LOST = "lost",
}

export enum ValidStageProspecto {
  PROSPECT = "prospect",
  CONTACTED = "contacted",
  FOLLOWUP = "followup",
  APPOINTMENT = "appointment",
  NEGOTIATIONS = "negotiations",
  PAPERWORK = "paperwork",
  CLOSURE = "closure",
}

export const statusProspecto = [
  {
    key: ValidStatusProspectos.EN_PROGRESO,
    titlePlural: "En proceso",
    titleSingular: "En Proceso",
    icon: FaClock,
    className: "hover:bg-orange-200/50 transition duration-300",
    classNameSelected: "bg-orange-200/70 dark:bg-orange-600",
  },
  {
    key: ValidStatusProspectos.STANDBY,
    titlePlural: "Standby",
    titleSingular: "Standby",
    icon: CgSandClock,
    className: "hover:bg-fuchsia-200/50 transition duration-300",
    classNameSelected: "bg-fuchsia-200/70 dark:bg-fuchsia-600",
  },
  {
    key: ValidStatusProspectos.WON,
    titlePlural: "Ganadas",
    titleSingular: "Ganada",
    icon: FaTrophy,
    className: "hover:bg-teal-200/50 transition duration-300",
    classNameSelected: "bg-teal-200/70 dark:bg-teal-600",
  },
  {
    key: ValidStatusProspectos.LOST,
    titlePlural: "Perdidas",
    titleSingular: "Perdida",
    icon: LiaTimesSolid,
    className: "hover:bg-red-200/50 transition duration-300",
    classNameSelected: "bg-red-200/70 dark:bg-red-600",
  },
];

export const stagesProspecto = [
  { key: ValidStageProspecto.PROSPECT, title: "Nuevo", icon: TbUserPentagon },
  { key: ValidStageProspecto.CONTACTED, title: "Intento de contacto" },
  { key: ValidStageProspecto.FOLLOWUP, title: "Seguimiento", icon: MdFollowTheSigns },

  { key: ValidStageProspecto.APPOINTMENT, title: "Cita", icon: BsCalendarDate },
  { key: ValidStageProspecto.NEGOTIATIONS, title: "Negociación", icon: FaRegHandshake },
  { key: ValidStageProspecto.PAPERWORK, title: "Papelería", icon: BsEnvelopePaper },
  { key: ValidStageProspecto.CLOSURE, title: "Cierre", icon: LuGoal },
];

export const stagesProspectoFilteredProspects = stagesProspecto.filter(
  (stage) =>
    stage.key === ValidStageProspecto.PROSPECT || stage.key === ValidStageProspecto.CONTACTED
);

export const stagesProspectoFilteredOportunities = stagesProspecto.filter(
  (stage) =>
    stage.key === ValidStageProspecto.APPOINTMENT ||
    stage.key === ValidStageProspecto.NEGOTIATIONS ||
    stage.key === ValidStageProspecto.PAPERWORK ||
    stage.key === ValidStageProspecto.CLOSURE ||
    stage.key === ValidStageProspecto.FOLLOWUP
);

export const isProspectOpportunity = (prospect: Lead) => {
  if (!prospect) return false;
  return stagesProspectoFilteredOportunities.some((stage) => stage.key === prospect?.stage);
};

export type QueryStateProspects =
  | (QueryObserverRefetchErrorResult<ResponseGetLeads, Error> & {
      setData: React.Dispatch<React.SetStateAction<ResponseGetLeads>>;
      isLoading: boolean;
      data: ResponseGetLeads;
    })
  | (QueryObserverSuccessResult<ResponseGetLeads, Error> & {
      setData: React.Dispatch<React.SetStateAction<ResponseGetLeads>>;
      isLoading: boolean;
      data: ResponseGetLeads;
    });
