import { useAuthStore } from "@/store";
import { validPaths } from "@/utils";
import { Navigate } from "react-router-dom";

interface IPublicProtectedRouteProps {
  children?: React.ReactNode;
}

const PublicProtectedRoute = (props: IPublicProtectedRouteProps) => {
  const { authenticated } = useAuthStore();

  return authenticated ? <Navigate to={validPaths.dashboard.path} /> : props.children;
};

export default PublicProtectedRoute;
