import { useAuthStore } from "@/store";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Loader from "../@ui/Loader";
import { PageProps, publicRoutes, validPaths } from "@/utils";

const publicRoutesStr = Object.keys(publicRoutes).map((key) => {
  const route = publicRoutes[key as keyof typeof publicRoutes] as unknown as PageProps;
  return route.path;
});

interface IProtectedRouteProps {
  children?: React.ReactNode;
}

const ProtectedRoute = (props: IProtectedRouteProps) => {
  const { authenticated, loading } = useAuthStore();
  const location = useLocation();

  if (loading) return <Loader loading={true} />;

  if (!authenticated && !loading) {
    if (publicRoutesStr.includes(location.pathname)) return props.children;
    return <Navigate to={validPaths.home.path} replace />;
  }

  return props.children;
};

export default ProtectedRoute;
