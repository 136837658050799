import { axiosClient } from "@/utils";
import { AxiosRequestConfig } from "axios";
import { Serializer } from "./api/serialize";
import { MetaResponse, transformarObjetoAFilter } from ".";
import { Broker, Property, Tag } from "@/interfaces";
import groupBy from "lodash/groupBy";

const prefix = "/api/v1/brokers";

export const brokerService = {
  properties: {
    getAll: async (props?: ParamsGetProperties): Promise<ResponseGetProperties> => {
      const { page, sort, include, filters, search } = props || {};
      const params: AxiosRequestConfig["params"] = {
        ...(filters && transformarObjetoAFilter(filters)),
        ...(include && { include: include.join(",") }),
        ...(page && { "page[number]": page.page, "page[size]": page.size }),
        ...(sort && { sort }),
        ...(search && { "query[title_i_cont]": search }),
      };
      const res = await axiosClient.get(`${prefix}/properties`, { params });
      /* const res = await axiosClient.get("/api/v1/properties", { params }); */
      return new Serializer().deserialize(res.data);
    },
    getOne: async (id: number | string, params?: ParamsGetProperty): Promise<Property> => {
      const { include } = params || {};

      const newParams = params && {
        ...(include && { include: include.join(",") }),
      };

      /*       const res = await axiosClient.get("/api/v1/properties/" + id, {
        params: newParams,
      }); */
      const res = await axiosClient.get(`${prefix}/properties/${id}`, { params: newParams });
      return (await new Serializer().deserialize(res.data)).data;
    },
    create: async (data: CreatePropertyDto | FormData): Promise<Property> => {
      return (
        await new Serializer().deserialize(
          (
            await axiosClient.post(`${prefix}/properties/`, data)
          ).data
        )
      ).data;
    },

    update: async (term: string | number, data: any): Promise<Property> => {
      const res = (await axiosClient.patch(`${prefix}/properties/${term}`, data)).data;

      if (res.errors) {
        throw new Error(res.errors[0].detail || "Ha ocurrido un error");
      }
      return (await new Serializer().deserialize(res)).data;
    },
    delete: (term: string | number) => {
      return axiosClient.delete(`${prefix}/properties/${term}`);
    },
  },
  tags: {
    get: async (): Promise<TagResponse> => {
      const req = await axiosClient.get(prefix + "/tags");
      const tagsResponse = new Serializer().deserialize(req.data.data);
      //@ts-ignore
      return groupBy<Tag>(tagsResponse, (tag) => tag.attributes.category);
    },

    create: async (data: CreateTagDto): Promise<{ data: Tag }> => {
      const req = await axiosClient.post(prefix + "/tags", data);
      return req.data;
    },
    update: async (id: number | string, body: UpdateTagDto): Promise<{ data: Tag }> => {
      const req = await axiosClient.patch(prefix + "/tags/" + id, body);
      return req.data;
    },
    delete: async (id: number | string) => {
      const req = await axiosClient.delete(prefix + "/tags/" + id);
      return req.data;
    },
  },
  accounts: {
    getAll: async (): Promise<GetAccountsBrokerResponse> => {
      return new Serializer().deserialize(
        (await axiosClient.get(prefix + "/accounts?include=profile&sort=account.id")).data
      );
    },

    getOne: async (id: string | number): Promise<AccountBroker> => {
      const res = await axiosClient.get(prefix + `/accounts/${id}?include=profile`);
      return (await new Serializer().deserialize(res.data)).data;
    },

    create: async (data: SetAccountBroker | FormData): Promise<AccountBroker> => {
      const response = (await axiosClient.post(prefix + "/account_profiles", data)).data;
      if (Array.isArray(response.email)) {
        throw new Error("Este correo ya existe");
      }

      return (await new Serializer().deserialize(response)).data;
    },

    update: async (
      id: string | number,
      data: SetAccountBroker | FormData
    ): Promise<AccountBroker> => {
      return (
        await new Serializer().deserialize(
          (
            await axiosClient.patch(prefix + `/accounts/${id}?include=profile`, data)
          ).data
        )
      ).data;
    },

    updateMe: async (
      id: string | number,
      data: Omit<Partial<SetAccountBroker>, "terms_agreement" | "role"> | FormData
    ) => {
      return await axiosClient.patch<{
        data: {
          attributes: {
            avatar: {
              id: number;
              url: string;
            };
            cellphone: string;
            first_name: string;
            last_name: string;
          };
        };
      }>(prefix + `/accounts/${id}/profile`, data);
    },

    delete: async (id: string | number) => {
      return (await axiosClient.delete(prefix + `/accounts/${id}`)).data;
    },
  },
  attachments: {
    movePosition: (props: { id: number | string; position: number }) =>
      axiosClient.patch(prefix + "/attachments/" + props.id, { position: props.position }),
    delete: (id: number | string) => axiosClient.delete(prefix + "/attachments/" + id),
  },

  current: async () => {
    return (await axiosClient.get<{ data: Broker }>(prefix + "/current")).data.data;
  },
};

export interface TagResponse {
  propLocation: Tag[];
  propTypes: Tag[];
  propStatus: Tag[];
  propAmenities: Tag[];
  propQualifiers: Tag[];
  leadOrigin: Tag[];
  leadMedium: Tag[];
}

export interface ResponseGetProperties {
  data: Property[];
  meta: MetaResponse;
}

export interface ParamsGetProperties {
  page?: {
    page: number | string;
    size: number | string;
  };
  search?: string;
  sort?: string;
  include?: (
    | "features"
    | "features.tag"
    | "location"
    | "agent"
    | "type"
    | "agent"
    | "agent.profile"
    | "status"
  )[];
  filters?: {
    [key: string]: string[] | string;
  };
}

export interface CreatePropertyDto {
  title: string;
  description: string;
  rooms: number;
  bathrooms: number;
  floors: number;
  status_tag_key?: string;
  type_tag_key?: string;
  location_tag_key?: string;
  published: boolean;
  images?: any[];
  plans?: any[];
  retail_price: number;
  retail_price_currency: string;
  rent_price: number;
  rent_price_currency: string;
  parking_lots: number;
  area: number;
  area_units: string;
  street_address: string;
  street_number: string;
  postal_code: string;
  latitude: number;
  longitude: number;
  video_url: string;
  built_at: string;
  agent_id?: number;
  tag_ids: string[];
}

export interface ParamsGetProperty extends Pick<ParamsGetProperties, "include"> {}

export interface AccountBroker {
  email: string;
  id: string;
  profile: {
    avatar: { id: number; url: string };
    cellphone: string;
    first_name: string;
    id: string;
    last_name: string;
  };
  role: "user" | "admin" | "agent";
  status: 1 | 2;
}

export interface GetAccountsBrokerResponse {
  data: AccountBroker[];
}

export interface SetAccountBroker {
  email?: string;
  role?: AccountBroker["role"];
  first_name?: string;
  last_name?: string;
  cellphone?: string;
  terms_agreement?: boolean;
  password?: string;
  avatar?: File | null;
}

export interface CreateTagDto {
  key: string;
  label: string;
  category: string;
}

export interface UpdateTagDto extends Pick<CreateTagDto, "label"> {}
